import React from "react";

function FooterItem() {
  return (
    <>
      <li className="footer__item">
        <p className="footer__text" style={{}}>Email: <a href="mailto:info@vsporte.ru" className="footer__connect">vsportespb@yandex.ru</a></p>
      </li>
      <li className="footer__item" style={{ visibility: 'hidden' }}>
        <p className="footer__text">Сетевое издание ВСпорте_СПб</p>
      </li>
      <li className="footer__item" style={{ visibility: 'hidden' }} >
        <p className="footer__text">Размещение рекламы: <a href="mailto:adv@vsporte.ru" className="footer__connect">adv@vsporte.ru</a></p>
      </li>
      <li className="footer__item"  style={{ visibility: 'hidden' }} >
        <p className="footer__text">Главный редактор: Иванов Иван Иванович</p>
      </li>
      <li className="footer__item" style={{ visibility: 'hidden' }} >
        <p className="footer__text">Регистрация СМИ 10.10.2023 года</p>
      </li>
      <li className="footer__item" style={{ visibility: 'hidden' }} >
        <p className="footer__text">Телефон редакции СМИ: <a href="tel:+78121111214" className="footer__connect">+7 (812) 111-12-14</a></p>
      </li>
      <li className="footer__item" style={{ visibility: 'hidden' }} >
        <p className="footer__text">Роскомнадзором за номером Эл №ФС00-00000</p>
      </li>
    </>
  );
}

export default FooterItem;
