import React from "react";
import Select from 'react-select';
import {Option} from "../../../core/types/common";
import {filteringPostsBySport} from "../../../core/store/default-posts-data/default-posts-data";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {getPostsFilterSportStatus} from "../../../core/store/default-posts-data/selectors";
import {
  filteringEventsByMonth,
  filteringEventsByPastFuture,
  filteringEventsBySeason,
  filteringEventsBySport
} from "../../../core/store/events-data/events-data";
import {
  getEventsFilterMonthStatus,
  getEventsFilterPastFutureStatus,
  getEventsFilterSeasonStatus,
  getEventsFilterSportStatus
} from "../../../core/store/events-data/selector";
import {DropdownPlaceholders, DropdownTypes, ItemsForFilter} from "../../../core/constants/filters";
import {
  getCompetitionsFilterSeasonStatus,
  getCompetitionsFilterSportStatus,
  getWidgetTablesFilterCompetitionStatus,
  getWidgetTablesFilterSportStatus
} from '../../../core/store/competitions-data/selectors'
import {
  filteringCompetitionsBySeason,
  filteringCompetitionsBySport,
  filteringWidgetTablesByCompetition,
  filteringWidgetTablesBySport
} from '../../../core/store/competitions-data/competitions-data'
import {getOrganizationsFilterSportStatus} from "../../../core/store/organizations-data/selectors";
import {filteringOrganizationsBySport} from "../../../core/store/organizations-data/organizations-data";
import { fetchDefaultPosts } from '../../../core/store/api-actions'

function Dropdown({options, type, items, onMenuScrollToBottom}: {
  options: Option[],
  type: string,
  items: string,
  onMenuScrollToBottom?: (e: WheelEvent | TouchEvent) => void
}) {
  const dispatch = useAppDispatch();
  const createPlaceholder = (type: string) => {
    switch (type) {
      case DropdownTypes.Sport:
        return DropdownPlaceholders.Sport;
      case DropdownTypes.Season:
        return DropdownPlaceholders.Season;
      case DropdownTypes.Month:
        return DropdownPlaceholders.Month;
      case DropdownTypes.PastFuture:
        return DropdownPlaceholders.PastFuture;
      case DropdownTypes.Competition:
        return DropdownPlaceholders.Competition;
      default:
        return DropdownPlaceholders.Undefined;
    }
  };

  const createValue = (items: string) => {
    switch (type) {
      case DropdownTypes.Sport:
        switch (items) {
          case ItemsForFilter.Posts:
            return useAppSelector(getPostsFilterSportStatus);
          case ItemsForFilter.Events:
            return useAppSelector(getEventsFilterSportStatus);
          case ItemsForFilter.Competitions:
            return useAppSelector(getCompetitionsFilterSportStatus);
          case ItemsForFilter.Organizations:
            return useAppSelector(getOrganizationsFilterSportStatus);
          case ItemsForFilter.WidgetCompetitions:
            return useAppSelector(getWidgetTablesFilterSportStatus);
        }
        break;
      case DropdownTypes.Season:
        switch (items) {
          case ItemsForFilter.Events:
            return useAppSelector(getEventsFilterSeasonStatus);
          case ItemsForFilter.Competitions:
            return useAppSelector(getCompetitionsFilterSeasonStatus);
        }
        break
      case DropdownTypes.Month:
        switch (items) {
          case ItemsForFilter.Events:
            return useAppSelector(getEventsFilterMonthStatus);
        }
        break
      case DropdownTypes.PastFuture:
        switch (items) {
          case ItemsForFilter.Events:
            return useAppSelector(getEventsFilterPastFutureStatus);
        }
        break
      case DropdownTypes.Competition:
        switch (items) {
          case ItemsForFilter.WidgetCompetitions:
            return useAppSelector(getWidgetTablesFilterCompetitionStatus);
        }
        break
    }
  };

  const handleFilterChange = (selectedOption: Option | null) => {
    if (selectedOption) {
      switch (type) {
        case DropdownTypes.Sport:
          switch (items) {
            case ItemsForFilter.Posts:
              dispatch(filteringPostsBySport({filterSportStatus: selectedOption}));
              const sportKindId = Number(selectedOption.label);
              dispatch(fetchDefaultPosts({ sportKindId }));
              break;
            case ItemsForFilter.Events:
              dispatch(filteringEventsBySport({filterSportStatus: selectedOption}));
              break;
            case ItemsForFilter.Competitions:
              dispatch(filteringCompetitionsBySport({filterSportStatus: selectedOption}));
              break;
            case ItemsForFilter.WidgetCompetitions:
              dispatch(filteringWidgetTablesBySport({filterWidgetSportStatus: selectedOption}));
              break;
            case ItemsForFilter.Organizations:
              dispatch(filteringOrganizationsBySport({filterSportStatus: selectedOption}));
              break;
            default:
              break;
          }
          break;
        case DropdownTypes.Season:
          switch (items) {
            case ItemsForFilter.Events:
              dispatch(filteringEventsBySeason({filterSeasonStatus: selectedOption}));
              break;
            case ItemsForFilter.Competitions:
              dispatch(filteringCompetitionsBySeason({filterSeasonStatus: selectedOption}));
              break;
            default:
              break;
          }
          break;
        case DropdownTypes.Month:
          switch (items) {
            case ItemsForFilter.Events:
              dispatch(filteringEventsByMonth({filterMonthStatus: selectedOption}));
              break;
            default:
              break;
          }
          break;
        case DropdownTypes.PastFuture:
          switch (items) {
            case ItemsForFilter.Events:
              dispatch(filteringEventsByPastFuture({filterPastFutureStatus: selectedOption}));
              break;
            default:
              break;
          }
          break;
        case DropdownTypes.Competition:
          switch (items) {
            case ItemsForFilter.WidgetCompetitions:
              dispatch(filteringWidgetTablesByCompetition({filterWidgetCompetitionStatus: selectedOption}));
              break;
            default:
              break;
          }
          break;
      }
    }
  };

  return (
    <Select
      defaultValue={options[0]}
      options={options}
      isSearchable={false}
      placeholder={createPlaceholder(type)}
      onChange={handleFilterChange}
      classNamePrefix={items === ItemsForFilter.WidgetCompetitions ? 'select-blue' : 'select'}
      className={items === ItemsForFilter.WidgetCompetitions ? 'select-blue' : 'select'}
      value={createValue(items)}
      onMenuScrollToBottom={(e) => {onMenuScrollToBottom && onMenuScrollToBottom(e)}}
    />
  );
}

export default Dropdown;
