/* tslint:disable */
/* eslint-disable */
/**
 * Документация scspb.api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 *
 * @export
 * @interface Album
 */
export interface Album {
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'album_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'author': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'cover_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'is_public': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'source'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Album
     */
    'source_url'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Album
     */
    'updated_at'?: number;
    /**
     * Database fields:
     * @type {Array<AlbumPhoto>}
     * @memberof Album
     */
    'photos'?: Array<AlbumPhoto>;
    /**
     *
     * @type {AlbumPhoto}
     * @memberof Album
     */
    'cover'?: AlbumPhoto;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof Album
     */
    'tags'?: Array<Tag>;
    /**
     *
     * @type {AlbumLinks}
     * @memberof Album
     */
    '_links'?: AlbumLinks;
}
/**
 * Database fields:
 * @export
 * @interface AlbumLinks
 */
export interface AlbumLinks {
    /**
     *
     * @type {AlbumLinksCoverUrls}
     * @memberof AlbumLinks
     */
    'cover_urls'?: AlbumLinksCoverUrls;
}
/**
 * Database fields:
 * @export
 * @interface AlbumLinksCoverUrls
 */
export interface AlbumLinksCoverUrls {
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof AlbumLinksCoverUrls
     */
    'thumb'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof AlbumLinksCoverUrls
     */
    '1920x1080'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof AlbumLinksCoverUrls
     */
    '370x210'?: CompetitionLinksLogo;
}
/**
 *
 * @export
 * @interface AlbumPhoto
 */
export interface AlbumPhoto {
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'photo_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'album_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof AlbumPhoto
     */
    'image': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof AlbumPhoto
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'updated_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof AlbumPhoto
     */
    'updated_by'?: number;
    /**
     *
     * @type {AlbumPhotoLinks}
     * @memberof AlbumPhoto
     */
    '_links'?: AlbumPhotoLinks;
}
/**
 * Database fields:
 * @export
 * @interface AlbumPhotoLinks
 */
export interface AlbumPhotoLinks {
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof AlbumPhotoLinks
     */
    'image'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof AlbumPhotoLinks
     */
    '1920x1080'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof AlbumPhotoLinks
     */
    '370x210'?: CompetitionLinksLogo;
}
/**
 *
 * @export
 * @interface ApiSearchPostsGet200Response
 */
export interface ApiSearchPostsGet200Response {
    /**
     *
     * @type {number}
     * @memberof ApiSearchPostsGet200Response
     */
    'total'?: number;
    /**
     *
     * @type {Array<Post>}
     * @memberof ApiSearchPostsGet200Response
     */
    'items'?: Array<Post>;
}
/**
 *
 * @export
 * @interface ApiSearchPostsGroupedGet200Response
 */
export interface ApiSearchPostsGroupedGet200Response {
    /**
     *
     * @type {ApiSearchPostsGroupedGet200ResponseDefault}
     * @memberof ApiSearchPostsGroupedGet200Response
     */
    'default'?: ApiSearchPostsGroupedGet200ResponseDefault;
    /**
     *
     * @type {ApiSearchPostsGroupedGet200ResponseEvent}
     * @memberof ApiSearchPostsGroupedGet200Response
     */
    'event'?: ApiSearchPostsGroupedGet200ResponseEvent;
    /**
     *
     * @type {ApiSearchPostsGroupedGet200ResponseMatch}
     * @memberof ApiSearchPostsGroupedGet200Response
     */
    'match'?: ApiSearchPostsGroupedGet200ResponseMatch;
}
/**
 *
 * @export
 * @interface ApiSearchPostsGroupedGet200ResponseDefault
 */
export interface ApiSearchPostsGroupedGet200ResponseDefault {
    /**
     *
     * @type {number}
     * @memberof ApiSearchPostsGroupedGet200ResponseDefault
     */
    'total'?: number;
    /**
     *
     * @type {Array<DefaultPost>}
     * @memberof ApiSearchPostsGroupedGet200ResponseDefault
     */
    'items'?: Array<DefaultPost>;
}
/**
 *
 * @export
 * @interface ApiSearchPostsGroupedGet200ResponseEvent
 */
export interface ApiSearchPostsGroupedGet200ResponseEvent {
    /**
     *
     * @type {number}
     * @memberof ApiSearchPostsGroupedGet200ResponseEvent
     */
    'total'?: number;
    /**
     *
     * @type {Array<EventPost>}
     * @memberof ApiSearchPostsGroupedGet200ResponseEvent
     */
    'items'?: Array<EventPost>;
}
/**
 *
 * @export
 * @interface ApiSearchPostsGroupedGet200ResponseMatch
 */
export interface ApiSearchPostsGroupedGet200ResponseMatch {
    /**
     *
     * @type {number}
     * @memberof ApiSearchPostsGroupedGet200ResponseMatch
     */
    'total'?: number;
    /**
     *
     * @type {Array<MatchPost>}
     * @memberof ApiSearchPostsGroupedGet200ResponseMatch
     */
    'items'?: Array<MatchPost>;
}
/**
 *
 * @export
 * @interface ApiVersionGet200Response
 */
export interface ApiVersionGet200Response {
    /**
     *
     * @type {string}
     * @memberof ApiVersionGet200Response
     */
    'version'?: string;
}
/**
 *
 * @export
 * @interface ApiVotesIdAnswerPost200Response
 */
export interface ApiVotesIdAnswerPost200Response {
    /**
     *
     * @type {boolean}
     * @memberof ApiVotesIdAnswerPost200Response
     */
    'success'?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof ApiVotesIdAnswerPost200Response
     */
    'errors'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ApiVotesIdAnswerPost200Response
     */
    'hash'?: string;
}
/**
 *
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'banner_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'size': BannerSizeEnum;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'position': BannerPositionEnum;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'rank'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'link'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'image'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'is_public'?: number;
    /**
     * В новой вкладке
     * @type {number}
     * @memberof Banner
     */
    'blank'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'start'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Banner
     */
    'end'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Banner
     */
    'updated_at'?: number;
    /**
     *
     * @type {BannerLinks}
     * @memberof Banner
     */
    '_links'?: BannerLinks;
}

export const BannerSizeEnum = {
    Main: 'main',
    Sidebar: 'sidebar'
} as const;

export type BannerSizeEnum = typeof BannerSizeEnum[keyof typeof BannerSizeEnum];
export const BannerPositionEnum = {
    AfterNewsSlider: 'after-news-slider',
    Sidebar: 'sidebar',
    Header: 'header'
} as const;

export type BannerPositionEnum = typeof BannerPositionEnum[keyof typeof BannerPositionEnum];

/**
 * Database fields:
 * @export
 * @interface BannerLinks
 */
export interface BannerLinks {
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof BannerLinks
     */
    'image'?: CompetitionLinksLogo;
}
/**
 *
 * @export
 * @interface Competition
 */
export interface Competition {
    /**
     * Database fields:
     * @type {number}
     * @memberof Competition
     */
    'competition_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Competition
     */
    'sport_kind_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Competition
     */
    'season_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Competition
     */
    'full_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Competition
     */
    'short_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Competition
     */
    'logo'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Competition
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Competition
     */
    'start_dt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Competition
     */
    'end_dt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Competition
     */
    'status'?: CompetitionStatusEnum;
    /**
     * Database fields:
     * @type {string}
     * @memberof Competition
     */
    'statusLabel'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Competition
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Competition
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Competition
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Competition
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Competition
     */
    'updated_at'?: number;
    /**
     *
     * @type {HdbkSportKind}
     * @memberof Competition
     */
    'sportKind'?: HdbkSportKind;
    /**
     *
     * @type {HdbkSeason}
     * @memberof Competition
     */
    'season'?: HdbkSeason;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof Competition
     */
    'tags'?: Array<Tag>;
    /**
     * Database fields:
     * @type {Array<MatchPost>}
     * @memberof Competition
     */
    'matches'?: Array<MatchPost>;
    /**
     *
     * @type {CompetitionLinks}
     * @memberof Competition
     */
    '_links'?: CompetitionLinks;
}

export const CompetitionStatusEnum = {
    Current: 'current',
    Past: 'past',
    Future: 'future'
} as const;

export type CompetitionStatusEnum = typeof CompetitionStatusEnum[keyof typeof CompetitionStatusEnum];

/**
 * Database fields:
 * @export
 * @interface CompetitionLinks
 */
export interface CompetitionLinks {
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof CompetitionLinks
     */
    'logo'?: CompetitionLinksLogo;
}
/**
 * Database fields:
 * @export
 * @interface CompetitionLinksLogo
 */
export interface CompetitionLinksLogo {
    /**
     * Database fields:
     * @type {string}
     * @memberof CompetitionLinksLogo
     */
    'href'?: string;
}
/**
 *
 * @export
 * @interface CompetitionTable
 */
export interface CompetitionTable {
    /**
     * Database fields:
     * @type {number}
     * @memberof CompetitionTable
     */
    'table_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof CompetitionTable
     */
    'competition_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof CompetitionTable
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof CompetitionTable
     */
    'public_title'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof CompetitionTable
     */
    'in_widget'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof CompetitionTable
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof CompetitionTable
     */
    'updated_at': string;
    /**
      * Database fields:
      * @type {number}
      * @memberof CompetitionTable
      */
    'rank'?: number;
    /**
      * Database fields:
      * @type {object}
      * @memberof CompetitionTable
    */
    'table'?: Record<string, Record<string, string>>;

}
/**
 *
 * @export
 * @interface DefaultPost
 */
export interface DefaultPost {
    /**
     *
     * @type {string}
     * @memberof DefaultPost
     */
    'type'?: DefaultPostTypeEnum;
    /**
     *
     * @type {Array<HdbkSportKind>}
     * @memberof DefaultPost
     */
    'sportKinds'?: Array<HdbkSportKind>;
    /**
     * Database fields:
     * @type {number}
     * @memberof DefaultPost
     */
    'post_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof DefaultPost
     */
    'title'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof DefaultPost
     */
    'lead'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof DefaultPost
     */
    'text'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof DefaultPost
     */
    'author'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof DefaultPost
     */
    'source'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof DefaultPost
     */
    'image'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof DefaultPost
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof DefaultPost
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof DefaultPost
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof DefaultPost
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof DefaultPost
     */
    'in_slider'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof DefaultPost
     */
    'in_sidebar'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof DefaultPost
     */
    'viewed'?: number;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof DefaultPost
     */
    'tags'?: Array<Tag>;
    /**
     * Database fields:
     * @type {Array<HdbkDocument>}
     * @memberof DefaultPost
     */
    'documents'?: Array<HdbkDocument>;
    /**
     * Database fields:
     * @type {Array<Album>}
     * @memberof DefaultPost
     */
    'albums'?: Array<Album>;
    /**
     * Database fields:
     * @type {Array<Video>}
     * @memberof DefaultPost
     */
    'videos'?: Array<Video>;
    /**
     * Database fields:
     * @type {string}
     * @memberof DefaultPost
     */
    'href'?: string;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof DefaultPost
     */
    'self'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof DefaultPost
     */
    'image870x490'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof DefaultPost
     */
    'image460x260'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof DefaultPost
     */
    'image284x160'?: CompetitionLinksLogo;
    /**
     *
     * @type {DefaultPostLinks}
     * @memberof DefaultPost
     */
    '_links'?: DefaultPostLinks;
}

export const DefaultPostTypeEnum = {
    Post: 'post'
} as const;

export type DefaultPostTypeEnum = typeof DefaultPostTypeEnum[keyof typeof DefaultPostTypeEnum];

/**
 * Database fields:
 * @export
 * @interface DefaultPostLinks
 */
export interface DefaultPostLinks {
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof DefaultPostLinks
     */
    'self'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof DefaultPostLinks
     */
    'image870x490'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof DefaultPostLinks
     */
    'image460x260'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof DefaultPostLinks
     */
    'image284x160'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof DefaultPostLinks
     */
    'image'?: CompetitionLinksLogo;
}
/**
 *
 * @export
 * @interface EventPost
 */
export interface EventPost {
    /**
     *
     * @type {number}
     * @memberof EventPost
     */
    'competition_id'?: number;
    /**
     *
     * @type {string}
     * @memberof EventPost
     */
    'event_date'?: string;
    /**
     *
     * @type {string}
     * @memberof EventPost
     */
    'type'?: EventPostTypeEnum;
    /**
     *
     * @type {Array<HdbkSportKind>}
     * @memberof EventPost
     */
    'sportKinds'?: Array<HdbkSportKind>;
    /**
     * Database fields:
     * @type {number}
     * @memberof EventPost
     */
    'post_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof EventPost
     */
    'title'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof EventPost
     */
    'lead'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof EventPost
     */
    'text'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof EventPost
     */
    'author'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof EventPost
     */
    'source'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof EventPost
     */
    'image'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof EventPost
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof EventPost
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof EventPost
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof EventPost
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof EventPost
     */
    'in_slider'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof EventPost
     */
    'in_sidebar'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof EventPost
     */
    'viewed'?: number;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof EventPost
     */
    'tags'?: Array<Tag>;
    /**
     * Database fields:
     * @type {Array<HdbkDocument>}
     * @memberof EventPost
     */
    'documents'?: Array<HdbkDocument>;
    /**
     * Database fields:
     * @type {Array<Album>}
     * @memberof EventPost
     */
    'albums'?: Array<Album>;
    /**
     * Database fields:
     * @type {Array<Video>}
     * @memberof EventPost
     */
    'videos'?: Array<Video>;
    /**
     * Database fields:
     * @type {string}
     * @memberof EventPost
     */
    'href'?: string;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof EventPost
     */
    'self'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof EventPost
     */
    'image870x490'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof EventPost
     */
    'image460x260'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof EventPost
     */
    'image284x160'?: CompetitionLinksLogo;
    /**
     *
     * @type {DefaultPostLinks}
     * @memberof EventPost
     */
    '_links'?: DefaultPostLinks;
}

export const EventPostTypeEnum = {
    Event: 'event'
} as const;

export type EventPostTypeEnum = typeof EventPostTypeEnum[keyof typeof EventPostTypeEnum];

/**
 *
 * @export
 * @interface HdbkDocument
 */
export interface HdbkDocument {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'document_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'module': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'item_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'file'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'created_at'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkDocument
     */
    'updated_at'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkDocument
     */
    'updated_by'?: number;
    /**
     *
     * @type {HdbkDocumentLinks}
     * @memberof HdbkDocument
     */
    '_links'?: HdbkDocumentLinks;
}
/**
 * Database fields:
 * @export
 * @interface HdbkDocumentLinks
 */
export interface HdbkDocumentLinks {
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof HdbkDocumentLinks
     */
    'self'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof HdbkDocumentLinks
     */
    'file'?: CompetitionLinksLogo;
}
/**
 *
 * @export
 * @interface HdbkPartner
 */
export interface HdbkPartner {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'partner_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'logo': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'url': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'status'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'order'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'created_at'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'updated_at'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkPartner
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkPartner
     */
    'logoUrl'?: string;
}
/**
 *
 * @export
 * @interface HdbkSeason
 */
export interface HdbkSeason {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'season_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkSeason
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkSeason
     */
    'start_dt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkSeason
     */
    'end_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSeason
     */
    'updated_at'?: number;
}
/**
 *
 * @export
 * @interface HdbkSportKind
 */
export interface HdbkSportKind {
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSportKind
     */
    'sport_kind_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkSportKind
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof HdbkSportKind
     */
    'alias'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSportKind
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSportKind
     */
    'rank'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSportKind
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSportKind
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSportKind
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof HdbkSportKind
     */
    'updated_at'?: number;
}
/**
 *
 * @export
 * @interface MatchPost
 */
export interface MatchPost {
    /**
     *
     * @type {string}
     * @memberof MatchPost
     */
    'type'?: MatchPostTypeEnum;
    /**
     *
     * @type {number}
     * @memberof MatchPost
     */
    'competition_id'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchPost
     */
    'table_id'?: number;
    /**
     *
     * @type {object}
     * @memberof MatchPost
     */
    'table'?: Record<string, Record<string, string>>;
    /**
     *
     * @type {MatchPostMatch}
     * @memberof MatchPost
     */
    'match'?: MatchPostMatch;
    /**
     *
     * @type {MatchPostLinks}
     * @memberof MatchPost
     */
    '_links'?: MatchPostLinks;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPost
     */
    'post_id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPost
     */
    'title'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPost
     */
    'lead'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPost
     */
    'text'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPost
     */
    'author'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPost
     */
    'source'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPost
     */
    'image'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPost
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPost
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPost
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPost
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPost
     */
    'in_slider'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPost
     */
    'in_sidebar'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof MatchPost
     */
    'viewed'?: number;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof MatchPost
     */
    'tags'?: Array<Tag>;
    /**
     * Database fields:
     * @type {Array<HdbkDocument>}
     * @memberof MatchPost
     */
    'documents'?: Array<HdbkDocument>;
    /**
     * Database fields:
     * @type {Array<Album>}
     * @memberof MatchPost
     */
    'albums'?: Array<Album>;
    /**
     * Database fields:
     * @type {Array<Video>}
     * @memberof MatchPost
     */
    'videos'?: Array<Video>;
    /**
     * Database fields:
     * @type {Array<HdbkSportKind>}
     * @memberof MatchPost
     */
    'sportKinds'?: Array<HdbkSportKind>;
    /**
     * Database fields:
     * @type {string}
     * @memberof MatchPost
     */
    'href'?: string;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof MatchPost
     */
    'self'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof MatchPost
     */
    'image870x490'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof MatchPost
     */
    'image460x260'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof MatchPost
     */
    'image284x160'?: CompetitionLinksLogo;
}

export const MatchPostTypeEnum = {
    Match: 'match'
} as const;

export type MatchPostTypeEnum = typeof MatchPostTypeEnum[keyof typeof MatchPostTypeEnum];

/**
 *
 * @export
 * @interface MatchPostLinks
 */
export interface MatchPostLinks {
    /**
     *
     * @type {MatchPostLinksSelf}
     * @memberof MatchPostLinks
     */
    'self'?: MatchPostLinksSelf;
}
/**
 *
 * @export
 * @interface MatchPostLinksSelf
 */
export interface MatchPostLinksSelf {
    /**
     *
     * @type {string}
     * @memberof MatchPostLinksSelf
     */
    'href'?: string;
}
/**
 *
 * @export
 * @interface MatchPostMatch
 */
export interface MatchPostMatch {
    /**
     *
     * @type {string}
     * @memberof MatchPostMatch
     */
    'team1'?: string;
    /**
     *
     * @type {number}
     * @memberof MatchPostMatch
     */
    'gf'?: number;
    /**
     *
     * @type {number}
     * @memberof MatchPostMatch
     */
    'ga'?: number;
    /**
     *
     * @type {string}
     * @memberof MatchPostMatch
     */
    'team2'?: string;
}
/**
 *
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * Database fields:
     * @type {number}
     * @memberof Organization
     */
    'organization_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'full_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'short_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'photo'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'type'?: OrganizationTypeEnum;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'email'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'phone'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'latitude'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'longitude'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'address'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'is_public'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Organization
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Organization
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Organization
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Organization
     */
    'updated_at'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Organization
     */
    'type_label'?: string;
    /**
     * Database fields:
     * @type {Array<HdbkSportKind>}
     * @memberof Organization
     */
    'sportKinds'?: Array<HdbkSportKind>;
    /**
     * Database fields:
     * @type {Array<OrganizationTime>}
     * @memberof Organization
     */
    'times'?: Array<OrganizationTime>;
    /**
     * Database fields:
     * @type {Array<Social>}
     * @memberof Organization
     */
    'socials'?: Array<Social>;
    /**
     *
     * @type {OrganizationLinks}
     * @memberof Organization
     */
    '_links'?: OrganizationLinks;
}

export const OrganizationTypeEnum = {
    Governmental: 'governmental',
    Commercial: 'commercial'
} as const;

export type OrganizationTypeEnum = typeof OrganizationTypeEnum[keyof typeof OrganizationTypeEnum];

/**
 * Database fields:
 * @export
 * @interface OrganizationLinks
 */
export interface OrganizationLinks {
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof OrganizationLinks
     */
    'photo'?: CompetitionLinksLogo;
}
/**
 *
 * @export
 * @interface OrganizationTime
 */
export interface OrganizationTime {
    /**
     * Database fields:
     * @type {number}
     * @memberof OrganizationTime
     */
    'time_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof OrganizationTime
     */
    'organization_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof OrganizationTime
     */
    'day_number': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof OrganizationTime
     */
    'start': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof OrganizationTime
     */
    'end': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof OrganizationTime
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof OrganizationTime
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof OrganizationTime
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof OrganizationTime
     */
    'updated_at'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof OrganizationTime
     */
    'day_number_label'?: string;
}
/**
 *
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'page_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'text': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'alias': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'is_default'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'in_about'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'need_address'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'address'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'latitude'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'longitude'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Page
     */
    'need_image'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Page
     */
    'image'?: string;
    /**
     *
     * @type {PageLinks}
     * @memberof Page
     */
    '_links'?: PageLinks;
}
/**
 * Database fields:
 * @export
 * @interface PageLinks
 */
export interface PageLinks {
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof PageLinks
     */
    'self'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof PageLinks
     */
    'image1130x636'?: CompetitionLinksLogo;
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof PageLinks
     */
    'image'?: CompetitionLinksLogo;
}
/**
 *
 * @export
 * @interface Post
 */
export interface Post {
    /**
     * Database fields:
     * @type {number}
     * @memberof Post
     */
    'post_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'type': PostTypeEnum;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'lead': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'text': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'author'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'source'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'image'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Post
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Post
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Post
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Post
     */
    'in_slider'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Post
     */
    'in_sidebar'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Post
     */
    'viewed'?: number;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof Post
     */
    'tags'?: Array<Tag>;
    /**
     * Database fields:
     * @type {Array<HdbkDocument>}
     * @memberof Post
     */
    'documents'?: Array<HdbkDocument>;
    /**
     * Database fields:
     * @type {Array<Album>}
     * @memberof Post
     */
    'albums'?: Array<Album>;
    /**
     * Database fields:
     * @type {Array<Video>}
     * @memberof Post
     */
    'videos'?: Array<Video>;
    /**
     * Database fields:
     * @type {Array<HdbkSportKind>}
     * @memberof Post
     */
    'sportKinds'?: Array<HdbkSportKind>;
    /**
     *
     * @type {DefaultPostLinks}
     * @memberof Post
     */
    '_links'?: DefaultPostLinks;
}

export const PostTypeEnum = {
    Post: 'post',
    Event: 'event',
    Match: 'match'
} as const;

export type PostTypeEnum = typeof PostTypeEnum[keyof typeof PostTypeEnum];

/**
 *
 * @export
 * @interface Social
 */
export interface Social {
    /**
     * Database fields:
     * @type {number}
     * @memberof Social
     */
    'social_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Social
     */
    'module': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Social
     */
    'item_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Social
     */
    'social'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Social
     */
    'url': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Social
     */
    'rank'?: number;
}
/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * Database fields:
     * @type {number}
     * @memberof Subscription
     */
    'id'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Subscription
     */
    'email': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Subscription
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Subscription
     */
    'updated_at'?: number;
}
/**
 *
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * Database fields:
     * @type {number}
     * @memberof Tag
     */
    'tag_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tag
     */
    'name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tag
     */
    'full_name': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Tag
     */
    'module'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tag
     */
    'item_id'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tag
     */
    'color'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Tag
     */
    'frequency'?: number;
}
/**
 *
 * @export
 * @interface Video
 */
export interface Video {
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'video_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'author'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'url'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'embed_url'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'code'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'preview'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'time'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'is_public': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'is_actual'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'in_player'?: number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof Video
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Video
     */
    'updated_at'?: number;
    /**
     * Database fields:
     * @type {Array<Tag>}
     * @memberof Video
     */
    'tags'?: Array<Tag>;
    /**
     *
     * @type {VideoLinks}
     * @memberof Video
     */
    '_links'?: VideoLinks;
}
/**
 * Database fields:
 * @export
 * @interface VideoLinks
 */
export interface VideoLinks {
    /**
     *
     * @type {CompetitionLinksLogo}
     * @memberof VideoLinks
     */
    'previewUrl'?: CompetitionLinksLogo;
}
/**
 *
 * @export
 * @interface VideoPlaylist
 */
export interface VideoPlaylist {
    /**
     * Database fields:
     * @type {number}
     * @memberof VideoPlaylist
     */
    'playlist_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof VideoPlaylist
     */
    'title': string;
    /**
     * Database fields:
     * @type {string}
     * @memberof VideoPlaylist
     */
    'author'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof VideoPlaylist
     */
    'is_public': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof VideoPlaylist
     */
    'description'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof VideoPlaylist
     */
    'preview'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof VideoPlaylist
     */
    'publishedDt'?: string;
    /**
     * Database fields:
     * @type {string}
     * @memberof VideoPlaylist
     */
    'published_dt'?: string;
    /**
     * Database fields:
     * @type {number}
     * @memberof VideoPlaylist
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VideoPlaylist
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VideoPlaylist
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VideoPlaylist
     */
    'updated_at'?: number;
    /**
     * Database fields:
     * @type {Array<Video>}
     * @memberof VideoPlaylist
     */
    'videos'?: Array<Video>;
    /**
     *
     * @type {VideoLinks}
     * @memberof VideoPlaylist
     */
    '_links'?: VideoLinks;
}
/**
 *
 * @export
 * @interface Vote
 */
export interface Vote {
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'vote_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof Vote
     */
    'title': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'is_public'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'published_dt'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'start_dt'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'end_dt'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'on_main'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'updated_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'created_by'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof Vote
     */
    'updated_by'?: number;
    /**
     * Database fields:
     * @type {Array<VoteOption>}
     * @memberof Vote
     */
    'options'?: Array<VoteOption>;
}
/**
 *
 * @export
 * @interface VoteAnswer
 */
export interface VoteAnswer {
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteAnswer
     */
    'answer_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteAnswer
     */
    'vote_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteAnswer
     */
    'option_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteAnswer
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteAnswer
     */
    'updated_at'?: number;
}
/**
 *
 * @export
 * @interface VoteOption
 */
export interface VoteOption {
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteOption
     */
    'option_id': number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteOption
     */
    'vote_id': number;
    /**
     * Database fields:
     * @type {string}
     * @memberof VoteOption
     */
    'title': string;
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteOption
     */
    'place'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteOption
     */
    'created_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteOption
     */
    'updated_at'?: number;
    /**
     * Database fields:
     * @type {number}
     * @memberof VoteOption
     */
    'answersCount'?: number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Возвращает коллекцию Album[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiAlbumsGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAlbumsGet: async (page?: number, pageSize?: number, onMain?: ApiAlbumsGetOnMainEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/albums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (onMain !== undefined) {
                localVarQueryParameter['on_main'] = onMain;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Album по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAlbumsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAlbumsIdGet', 'id', id)
            const localVarPath = `/api/albums/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Banner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiBannersGetCurrentEnum} [current]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBannersGet: async (page?: number, pageSize?: number, current?: ApiBannersGetCurrentEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/banners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (current !== undefined) {
                localVarQueryParameter['current'] = current;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Banner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBannersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBannersIdGet', 'id', id)
            const localVarPath = `/api/banners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию соревнований Competition[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [sportKindId] ID вида спорта HdbkSportKind
         * @param {number} [seasonId] ID сезона HdbkSeason
         * @param {string} [name] Название соревнования Competition
         * @param {Array<ApiCompetitionsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsGet: async (page?: number, pageSize?: number, sportKindId?: number, seasonId?: number, name?: string, _with?: Array<ApiCompetitionsGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/competitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sportKindId !== undefined) {
                localVarQueryParameter['sport_kind_id'] = sportKindId;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['season_id'] = seasonId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Competition по ID
         * @param {number} id
         * @param {Array<ApiCompetitionsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdGet: async (id: number, _with?: Array<ApiCompetitionsIdGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompetitionsIdGet', 'id', id)
            const localVarPath = `/api/competitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает MatchPost[] по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdMatchesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompetitionsIdMatchesGet', 'id', id)
            const localVarPath = `/api/competitions/{id}/matches`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает MatchPost по ID
         * @param {number} id
         * @param {number} postId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdMatchesPostIdGet: async (id: number, postId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompetitionsIdMatchesPostIdGet', 'id', id)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('apiCompetitionsIdMatchesPostIdGet', 'postId', postId)
            const localVarPath = `/api/competitions/{id}/matches/{post_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"post_id"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию CompetitionTable[]
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdTablesGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompetitionsIdTablesGet', 'id', id)
            const localVarPath = `/api/competitions/{id}/tables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает CompetitionTable по ID
         * @param {number} id
         * @param {number} tableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdTablesTableIdGet: async (id: number, tableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompetitionsIdTablesTableIdGet', 'id', id)
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('apiCompetitionsIdTablesTableIdGet', 'tableId', tableId)
            const localVarPath = `/api/competitions/{id}/tables/{table_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"table_id"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Organization[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiOrganizationsGetTypeEnum} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsGet: async (page?: number, pageSize?: number, type?: ApiOrganizationsGetTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Organization по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganizationsIdGet', 'id', id)
            const localVarPath = `/api/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Page по alias
         * @param {ApiPagesAliasGetAliasEnum} alias
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesAliasGet: async (alias: ApiPagesAliasGetAliasEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alias' is not null or undefined
            assertParamExists('apiPagesAliasGet', 'alias', alias)
            const localVarPath = `/api/pages/{alias}`
                .replace(`{${"alias"}}`, encodeURIComponent(String(alias)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Page[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [page2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesGet: async (page?: number, pageSize?: number, page2?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page2 !== undefined) {
                localVarQueryParameter['page'] = page2;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Page по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPagesIdGet', 'id', id)
            const localVarPath = `/api/pages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkPartner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiPartnersGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnersGet: async (page?: number, pageSize?: number, onMain?: ApiPartnersGetOnMainEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (onMain !== undefined) {
                localVarQueryParameter['on_main'] = onMain;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает HdbkPartner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnersIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPartnersIdGet', 'id', id)
            const localVarPath = `/api/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию VideoPlaylist[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlaylistsGet: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/playlists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Playlist по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlaylistsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPlaylistsIdGet', 'id', id)
            const localVarPath = `/api/playlists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию DefaultPost[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [sportKindId]
         * @param {ApiPostsDefaultGetIsExclusiveEnum} [isExclusive] Признак тегирования тегом &lt;Эксклюзив&gt;
         * @param {ApiPostsDefaultGetIsTopEnum} [isTop] Признак тегирования тегом &lt;Топ&gt;
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {Array<ApiPostsDefaultGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsDefaultGet: async (page?: number, pageSize?: number, sportKindId?: number, isExclusive?: ApiPostsDefaultGetIsExclusiveEnum, isTop?: ApiPostsDefaultGetIsTopEnum, since?: string, to?: string, year?: number, month?: number, _with?: Array<ApiPostsDefaultGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/posts/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sportKindId !== undefined) {
                localVarQueryParameter['sport_kind_id'] = sportKindId;
            }

            if (isExclusive !== undefined) {
                localVarQueryParameter['is_exclusive'] = isExclusive;
            }

            if (isTop !== undefined) {
                localVarQueryParameter['is_top'] = isTop;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает DefaultPost по ID
         * @param {number} id
         * @param {Array<ApiPostsDefaultIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsDefaultIdGet: async (id: number, _with?: Array<ApiPostsDefaultIdGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPostsDefaultIdGet', 'id', id)
            const localVarPath = `/api/posts/default/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию EventPost[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [sportKindId]
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {string} [start]
         * @param {string} [end]
         * @param {Array<ApiPostsEventGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsEventGet: async (page?: number, pageSize?: number, sportKindId?: number, since?: string, to?: string, year?: number, month?: number, start?: string, end?: string, _with?: Array<ApiPostsEventGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/posts/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sportKindId !== undefined) {
                localVarQueryParameter['sport_kind_id'] = sportKindId;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает EventPost по ID
         * @param {number} id
         * @param {Array<ApiPostsEventIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsEventIdGet: async (id: number, _with?: Array<ApiPostsEventIdGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPostsEventIdGet', 'id', id)
            const localVarPath = `/api/posts/event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Post[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiPostsGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {ApiPostsGetInSliderEnum} [inSlider] Признак для вывода в слайдер на главную
         * @param {ApiPostsGetInSidebarEnum} [inSidebar] Признак для вывода в сайдбар
         * @param {ApiPostsGetIsExclusiveEnum} [isExclusive] Признак тегирования тегом &lt;Эксклюзив&gt;
         * @param {ApiPostsGetIsTopEnum} [isTop] Признак тегирования тегом &lt;Топ&gt;
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {Array<ApiPostsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsGet: async (page?: number, pageSize?: number, onMain?: ApiPostsGetOnMainEnum, inSlider?: ApiPostsGetInSliderEnum, inSidebar?: ApiPostsGetInSidebarEnum, isExclusive?: ApiPostsGetIsExclusiveEnum, isTop?: ApiPostsGetIsTopEnum, since?: string, to?: string, year?: number, month?: number, _with?: Array<ApiPostsGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (onMain !== undefined) {
                localVarQueryParameter['on_main'] = onMain;
            }

            if (inSlider !== undefined) {
                localVarQueryParameter['in_slider'] = inSlider;
            }

            if (inSidebar !== undefined) {
                localVarQueryParameter['in_sidebar'] = inSidebar;
            }

            if (isExclusive !== undefined) {
                localVarQueryParameter['is_exclusive'] = isExclusive;
            }

            if (isTop !== undefined) {
                localVarQueryParameter['is_top'] = isTop;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Post по ID
         * @param {number} id
         * @param {Array<ApiPostsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsIdGet: async (id: number, _with?: Array<ApiPostsIdGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPostsIdGet', 'id', id)
            const localVarPath = `/api/posts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию MatchPost[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiPostsMatchGetCompetitionStatusEnum} [competitionStatus]
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {string} [start]
         * @param {string} [end]
         * @param {Array<ApiPostsMatchGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsMatchGet: async (page?: number, pageSize?: number, competitionStatus?: ApiPostsMatchGetCompetitionStatusEnum, since?: string, to?: string, year?: number, month?: number, start?: string, end?: string, _with?: Array<ApiPostsMatchGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/posts/match`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (competitionStatus !== undefined) {
                localVarQueryParameter['competition_status'] = competitionStatus;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает MatchPost по ID
         * @param {number} id
         * @param {Array<ApiPostsMatchIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsMatchIdGet: async (id: number, _with?: Array<ApiPostsMatchIdGetWithEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPostsMatchIdGet', 'id', id)
            const localVarPath = `/api/posts/match/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_with) {
                localVarQueryParameter['with'] = _with.join(COLLECTION_FORMATS.csv);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию DefaultPost[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsDefaultGet: async (q: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('apiSearchPostsDefaultGet', 'q', q)
            const localVarPath = `/api/search/posts/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию EventPost[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsEventGet: async (q: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('apiSearchPostsEventGet', 'q', q)
            const localVarPath = `/api/search/posts/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Post[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsGet: async (q: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('apiSearchPostsGet', 'q', q)
            const localVarPath = `/api/search/posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию DefaultPost[], EventPost[] и MatchPost[] разбитых по группам
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsGroupedGet: async (q: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('apiSearchPostsGroupedGet', 'q', q)
            const localVarPath = `/api/search/posts/grouped`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию MatchPost[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsMatchGet: async (q: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('apiSearchPostsMatchGet', 'q', q)
            const localVarPath = `/api/search/posts/match`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию сезонов HdbkSeason[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeasonsGet: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает HdbkSeason по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeasonsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSeasonsIdGet', 'id', id)
            const localVarPath = `/api/seasons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ApiSocialsGetModuleEnum} [module]
         * @param {number} [itemId]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialsGet: async (module?: ApiSocialsGetModuleEnum, itemId?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/socials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (module !== undefined) {
                localVarQueryParameter['module'] = module;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSocialsIdGet', 'id', id)
            const localVarPath = `/api/socials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialsSiteGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/socials/site`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkSportKind[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiSportKindsGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSportKindsGet: async (page?: number, pageSize?: number, onMain?: ApiSportKindsGetOnMainEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sport-kinds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (onMain !== undefined) {
                localVarQueryParameter['on_main'] = onMain;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает HdbkSportKind по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSportKindsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSportKindsIdGet', 'id', id)
            const localVarPath = `/api/sport-kinds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию подписок Subscription[]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Добавить email в подписки
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionSubscribePost: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiSubscriptionSubscribePost', 'email', email)
            const localVarPath = `/api/subscription/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Отписать email от подписки
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionUnsubscribeDelete: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiSubscriptionUnsubscribeDelete', 'email', email)
            const localVarPath = `/api/subscription/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Video[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiVideosGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {ApiVideosGetInPlayerEnum} [inPlayer] Признак для вывода в плеер
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVideosGet: async (page?: number, pageSize?: number, onMain?: ApiVideosGetOnMainEnum, inPlayer?: ApiVideosGetInPlayerEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/videos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (onMain !== undefined) {
                localVarQueryParameter['on_main'] = onMain;
            }

            if (inPlayer !== undefined) {
                localVarQueryParameter['in_player'] = inPlayer;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Video по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVideosIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVideosIdGet', 'id', id)
            const localVarPath = `/api/videos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает самый актуальный Vote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesActualGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/actual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию Vote[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiVotesGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesGet: async (page?: number, pageSize?: number, onMain?: ApiVotesGetOnMainEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (onMain !== undefined) {
                localVarQueryParameter['on_main'] = onMain;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Создаёт ответ VoteAnswer
         * @param {number} id ID опроса
         * @param {number} optionId ID варианта ответа
         * @param {string} ip IP c которого пришел ответ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdAnswerPost: async (id: number, optionId: number, ip: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesIdAnswerPost', 'id', id)
            // verify required parameter 'optionId' is not null or undefined
            assertParamExists('apiVotesIdAnswerPost', 'optionId', optionId)
            // verify required parameter 'ip' is not null or undefined
            assertParamExists('apiVotesIdAnswerPost', 'ip', ip)
            const localVarPath = `/api/votes/{id}/answer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (optionId !== undefined) {
                localVarQueryParameter['option_id'] = optionId;
            }

            if (ip !== undefined) {
                localVarQueryParameter['ip'] = ip;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает коллекцию VoteAnswer[]
         * @param {string} id ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdAnswersGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesIdAnswersGet', 'id', id)
            const localVarPath = `/api/votes/{id}/answers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает VoteAnswer по hash
         * @param {string} id ID опроса
         * @param {string} hash Хеш ответа пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdAnswersHashGet: async (id: string, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesIdAnswersHashGet', 'id', id)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('apiVotesIdAnswersHashGet', 'hash', hash)
            const localVarPath = `/api/votes/{id}/answers/{hash}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Возвращает Vote по ID
         * @param {number} id ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesIdGet', 'id', id)
            const localVarPath = `/api/votes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Album[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiAlbumsGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAlbumsGet(page?: number, pageSize?: number, onMain?: ApiAlbumsGetOnMainEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Album>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAlbumsGet(page, pageSize, onMain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiAlbumsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Album по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAlbumsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Album>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAlbumsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiAlbumsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Banner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiBannersGetCurrentEnum} [current]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBannersGet(page?: number, pageSize?: number, current?: ApiBannersGetCurrentEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Banner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBannersGet(page, pageSize, current, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiBannersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Banner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBannersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Banner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBannersIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiBannersIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию соревнований Competition[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [sportKindId] ID вида спорта HdbkSportKind
         * @param {number} [seasonId] ID сезона HdbkSeason
         * @param {string} [name] Название соревнования Competition
         * @param {Array<ApiCompetitionsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompetitionsGet(page?: number, pageSize?: number, sportKindId?: number, seasonId?: number, name?: string, _with?: Array<ApiCompetitionsGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Competition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompetitionsGet(page, pageSize, sportKindId, seasonId, name, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCompetitionsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Competition по ID
         * @param {number} id
         * @param {Array<ApiCompetitionsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompetitionsIdGet(id: number, _with?: Array<ApiCompetitionsIdGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Competition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompetitionsIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCompetitionsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает MatchPost[] по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompetitionsIdMatchesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MatchPost>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompetitionsIdMatchesGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCompetitionsIdMatchesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает MatchPost по ID
         * @param {number} id
         * @param {number} postId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompetitionsIdMatchesPostIdGet(id: number, postId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchPost>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompetitionsIdMatchesPostIdGet(id, postId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCompetitionsIdMatchesPostIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию CompetitionTable[]
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompetitionsIdTablesGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompetitionTable>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompetitionsIdTablesGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCompetitionsIdTablesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает CompetitionTable по ID
         * @param {number} id
         * @param {number} tableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompetitionsIdTablesTableIdGet(id: number, tableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompetitionTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompetitionsIdTablesTableIdGet(id, tableId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiCompetitionsIdTablesTableIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Organization[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiOrganizationsGetTypeEnum} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsGet(page?: number, pageSize?: number, type?: ApiOrganizationsGetTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Organization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsGet(page, pageSize, type, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiOrganizationsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Organization по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiOrganizationsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Page по alias
         * @param {ApiPagesAliasGetAliasEnum} alias
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPagesAliasGet(alias: ApiPagesAliasGetAliasEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPagesAliasGet(alias, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPagesAliasGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Page[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [page2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPagesGet(page?: number, pageSize?: number, page2?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Page>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPagesGet(page, pageSize, page2, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPagesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Page по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPagesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPagesIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPagesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkPartner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiPartnersGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnersGet(page?: number, pageSize?: number, onMain?: ApiPartnersGetOnMainEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkPartner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnersGet(page, pageSize, onMain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPartnersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает HdbkPartner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnersIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkPartner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnersIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPartnersIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию VideoPlaylist[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlaylistsGet(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VideoPlaylist>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlaylistsGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPlaylistsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Playlist по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPlaylistsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoPlaylist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlaylistsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPlaylistsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию DefaultPost[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [sportKindId]
         * @param {ApiPostsDefaultGetIsExclusiveEnum} [isExclusive] Признак тегирования тегом &lt;Эксклюзив&gt;
         * @param {ApiPostsDefaultGetIsTopEnum} [isTop] Признак тегирования тегом &lt;Топ&gt;
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {Array<ApiPostsDefaultGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsDefaultGet(page?: number, pageSize?: number, sportKindId?: number, isExclusive?: ApiPostsDefaultGetIsExclusiveEnum, isTop?: ApiPostsDefaultGetIsTopEnum, since?: string, to?: string, year?: number, month?: number, _with?: Array<ApiPostsDefaultGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DefaultPost>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsDefaultGet(page, pageSize, sportKindId, isExclusive, isTop, since, to, year, month, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPostsDefaultGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает DefaultPost по ID
         * @param {number} id
         * @param {Array<ApiPostsDefaultIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsDefaultIdGet(id: number, _with?: Array<ApiPostsDefaultIdGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultPost>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsDefaultIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPostsDefaultIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию EventPost[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [sportKindId]
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {string} [start]
         * @param {string} [end]
         * @param {Array<ApiPostsEventGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsEventGet(page?: number, pageSize?: number, sportKindId?: number, since?: string, to?: string, year?: number, month?: number, start?: string, end?: string, _with?: Array<ApiPostsEventGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventPost>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsEventGet(page, pageSize, sportKindId, since, to, year, month, start, end, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPostsEventGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает EventPost по ID
         * @param {number} id
         * @param {Array<ApiPostsEventIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsEventIdGet(id: number, _with?: Array<ApiPostsEventIdGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventPost>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsEventIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPostsEventIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Post[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiPostsGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {ApiPostsGetInSliderEnum} [inSlider] Признак для вывода в слайдер на главную
         * @param {ApiPostsGetInSidebarEnum} [inSidebar] Признак для вывода в сайдбар
         * @param {ApiPostsGetIsExclusiveEnum} [isExclusive] Признак тегирования тегом &lt;Эксклюзив&gt;
         * @param {ApiPostsGetIsTopEnum} [isTop] Признак тегирования тегом &lt;Топ&gt;
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {Array<ApiPostsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsGet(page?: number, pageSize?: number, onMain?: ApiPostsGetOnMainEnum, inSlider?: ApiPostsGetInSliderEnum, inSidebar?: ApiPostsGetInSidebarEnum, isExclusive?: ApiPostsGetIsExclusiveEnum, isTop?: ApiPostsGetIsTopEnum, since?: string, to?: string, year?: number, month?: number, _with?: Array<ApiPostsGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Post>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsGet(page, pageSize, onMain, inSlider, inSidebar, isExclusive, isTop, since, to, year, month, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPostsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Post по ID
         * @param {number} id
         * @param {Array<ApiPostsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsIdGet(id: number, _with?: Array<ApiPostsIdGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPostsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию MatchPost[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiPostsMatchGetCompetitionStatusEnum} [competitionStatus]
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {string} [start]
         * @param {string} [end]
         * @param {Array<ApiPostsMatchGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsMatchGet(page?: number, pageSize?: number, competitionStatus?: ApiPostsMatchGetCompetitionStatusEnum, since?: string, to?: string, year?: number, month?: number, start?: string, end?: string, _with?: Array<ApiPostsMatchGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MatchPost>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsMatchGet(page, pageSize, competitionStatus, since, to, year, month, start, end, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPostsMatchGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает MatchPost по ID
         * @param {number} id
         * @param {Array<ApiPostsMatchIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostsMatchIdGet(id: number, _with?: Array<ApiPostsMatchIdGetWithEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchPost>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostsMatchIdGet(id, _with, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiPostsMatchIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию DefaultPost[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchPostsDefaultGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSearchPostsGroupedGet200ResponseDefault>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchPostsDefaultGet(q, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSearchPostsDefaultGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию EventPost[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchPostsEventGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSearchPostsGroupedGet200ResponseEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchPostsEventGet(q, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSearchPostsEventGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Post[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchPostsGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSearchPostsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchPostsGet(q, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSearchPostsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию DefaultPost[], EventPost[] и MatchPost[] разбитых по группам
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchPostsGroupedGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSearchPostsGroupedGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchPostsGroupedGet(q, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSearchPostsGroupedGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию MatchPost[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchPostsMatchGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiSearchPostsGroupedGet200ResponseMatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchPostsMatchGet(q, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSearchPostsMatchGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию сезонов HdbkSeason[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSeasonsGet(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkSeason>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSeasonsGet(page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSeasonsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает HdbkSeason по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSeasonsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkSeason>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSeasonsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSeasonsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {ApiSocialsGetModuleEnum} [module]
         * @param {number} [itemId]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSocialsGet(module?: ApiSocialsGetModuleEnum, itemId?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Social>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSocialsGet(module, itemId, page, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSocialsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSocialsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Social>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSocialsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSocialsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSocialsSiteGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Social>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSocialsSiteGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSocialsSiteGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkSportKind[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiSportKindsGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSportKindsGet(page?: number, pageSize?: number, onMain?: ApiSportKindsGetOnMainEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HdbkSportKind>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSportKindsGet(page, pageSize, onMain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSportKindsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает HdbkSportKind по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSportKindsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HdbkSportKind>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSportKindsIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSportKindsIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию подписок Subscription[]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Subscription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSubscriptionGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Добавить email в подписки
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionSubscribePost(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionSubscribePost(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSubscriptionSubscribePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Отписать email от подписки
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionUnsubscribeDelete(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionUnsubscribeDelete(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiSubscriptionUnsubscribeDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVersionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiVersionGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVersionGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiVersionGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Video[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiVideosGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {ApiVideosGetInPlayerEnum} [inPlayer] Признак для вывода в плеер
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVideosGet(page?: number, pageSize?: number, onMain?: ApiVideosGetOnMainEnum, inPlayer?: ApiVideosGetInPlayerEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Video>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVideosGet(page, pageSize, onMain, inPlayer, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiVideosGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Video по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVideosIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Video>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVideosIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiVideosIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает самый актуальный Vote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesActualGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesActualGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiVotesActualGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию Vote[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiVotesGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesGet(page?: number, pageSize?: number, onMain?: ApiVotesGetOnMainEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vote>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesGet(page, pageSize, onMain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiVotesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Создаёт ответ VoteAnswer
         * @param {number} id ID опроса
         * @param {number} optionId ID варианта ответа
         * @param {string} ip IP c которого пришел ответ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesIdAnswerPost(id: number, optionId: number, ip: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiVotesIdAnswerPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesIdAnswerPost(id, optionId, ip, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiVotesIdAnswerPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает коллекцию VoteAnswer[]
         * @param {string} id ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesIdAnswersGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoteAnswer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesIdAnswersGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiVotesIdAnswersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает VoteAnswer по hash
         * @param {string} id ID опроса
         * @param {string} hash Хеш ответа пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesIdAnswersHashGet(id: string, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesIdAnswersHashGet(id, hash, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiVotesIdAnswersHashGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Возвращает Vote по ID
         * @param {number} id ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiVotesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         *
         * @summary Возвращает коллекцию Album[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiAlbumsGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAlbumsGet(page?: number, pageSize?: number, onMain?: ApiAlbumsGetOnMainEnum, options?: any): AxiosPromise<Array<Album>> {
            return localVarFp.apiAlbumsGet(page, pageSize, onMain, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Album по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAlbumsIdGet(id: number, options?: any): AxiosPromise<Album> {
            return localVarFp.apiAlbumsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Banner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiBannersGetCurrentEnum} [current]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBannersGet(page?: number, pageSize?: number, current?: ApiBannersGetCurrentEnum, options?: any): AxiosPromise<Array<Banner>> {
            return localVarFp.apiBannersGet(page, pageSize, current, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Banner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBannersIdGet(id: number, options?: any): AxiosPromise<Banner> {
            return localVarFp.apiBannersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию соревнований Competition[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [sportKindId] ID вида спорта HdbkSportKind
         * @param {number} [seasonId] ID сезона HdbkSeason
         * @param {string} [name] Название соревнования Competition
         * @param {Array<ApiCompetitionsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsGet(page?: number, pageSize?: number, sportKindId?: number, seasonId?: number, name?: string, _with?: Array<ApiCompetitionsGetWithEnum>, options?: any): AxiosPromise<Array<Competition>> {
            return localVarFp.apiCompetitionsGet(page, pageSize, sportKindId, seasonId, name, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Competition по ID
         * @param {number} id
         * @param {Array<ApiCompetitionsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdGet(id: number, _with?: Array<ApiCompetitionsIdGetWithEnum>, options?: any): AxiosPromise<Competition> {
            return localVarFp.apiCompetitionsIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает MatchPost[] по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdMatchesGet(id: number, options?: any): AxiosPromise<Array<MatchPost>> {
            return localVarFp.apiCompetitionsIdMatchesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает MatchPost по ID
         * @param {number} id
         * @param {number} postId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdMatchesPostIdGet(id: number, postId: number, options?: any): AxiosPromise<MatchPost> {
            return localVarFp.apiCompetitionsIdMatchesPostIdGet(id, postId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию CompetitionTable[]
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdTablesGet(id: number, options?: any): AxiosPromise<Array<CompetitionTable>> {
            return localVarFp.apiCompetitionsIdTablesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает CompetitionTable по ID
         * @param {number} id
         * @param {number} tableId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompetitionsIdTablesTableIdGet(id: number, tableId: number, options?: any): AxiosPromise<CompetitionTable> {
            return localVarFp.apiCompetitionsIdTablesTableIdGet(id, tableId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Organization[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiOrganizationsGetTypeEnum} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsGet(page?: number, pageSize?: number, type?: ApiOrganizationsGetTypeEnum, options?: any): AxiosPromise<Array<Organization>> {
            return localVarFp.apiOrganizationsGet(page, pageSize, type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Organization по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationsIdGet(id: number, options?: any): AxiosPromise<Organization> {
            return localVarFp.apiOrganizationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Page по alias
         * @param {ApiPagesAliasGetAliasEnum} alias
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesAliasGet(alias: ApiPagesAliasGetAliasEnum, options?: any): AxiosPromise<Page> {
            return localVarFp.apiPagesAliasGet(alias, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Page[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [page2]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesGet(page?: number, pageSize?: number, page2?: number, options?: any): AxiosPromise<Array<Page>> {
            return localVarFp.apiPagesGet(page, pageSize, page2, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Page по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPagesIdGet(id: number, options?: any): AxiosPromise<Page> {
            return localVarFp.apiPagesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkPartner[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiPartnersGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnersGet(page?: number, pageSize?: number, onMain?: ApiPartnersGetOnMainEnum, options?: any): AxiosPromise<Array<HdbkPartner>> {
            return localVarFp.apiPartnersGet(page, pageSize, onMain, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает HdbkPartner по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnersIdGet(id: number, options?: any): AxiosPromise<HdbkPartner> {
            return localVarFp.apiPartnersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию VideoPlaylist[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlaylistsGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<VideoPlaylist>> {
            return localVarFp.apiPlaylistsGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Playlist по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPlaylistsIdGet(id: number, options?: any): AxiosPromise<VideoPlaylist> {
            return localVarFp.apiPlaylistsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию DefaultPost[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [sportKindId]
         * @param {ApiPostsDefaultGetIsExclusiveEnum} [isExclusive] Признак тегирования тегом &lt;Эксклюзив&gt;
         * @param {ApiPostsDefaultGetIsTopEnum} [isTop] Признак тегирования тегом &lt;Топ&gt;
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {Array<ApiPostsDefaultGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsDefaultGet(page?: number, pageSize?: number, sportKindId?: number, isExclusive?: ApiPostsDefaultGetIsExclusiveEnum, isTop?: ApiPostsDefaultGetIsTopEnum, since?: string, to?: string, year?: number, month?: number, _with?: Array<ApiPostsDefaultGetWithEnum>, options?: any): AxiosPromise<Array<DefaultPost>> {
            return localVarFp.apiPostsDefaultGet(page, pageSize, sportKindId, isExclusive, isTop, since, to, year, month, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает DefaultPost по ID
         * @param {number} id
         * @param {Array<ApiPostsDefaultIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsDefaultIdGet(id: number, _with?: Array<ApiPostsDefaultIdGetWithEnum>, options?: any): AxiosPromise<DefaultPost> {
            return localVarFp.apiPostsDefaultIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию EventPost[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {number} [sportKindId]
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {string} [start]
         * @param {string} [end]
         * @param {Array<ApiPostsEventGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsEventGet(page?: number, pageSize?: number, sportKindId?: number, since?: string, to?: string, year?: number, month?: number, start?: string, end?: string, _with?: Array<ApiPostsEventGetWithEnum>, options?: any): AxiosPromise<Array<EventPost>> {
            return localVarFp.apiPostsEventGet(page, pageSize, sportKindId, since, to, year, month, start, end, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает EventPost по ID
         * @param {number} id
         * @param {Array<ApiPostsEventIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsEventIdGet(id: number, _with?: Array<ApiPostsEventIdGetWithEnum>, options?: any): AxiosPromise<EventPost> {
            return localVarFp.apiPostsEventIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Post[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiPostsGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {ApiPostsGetInSliderEnum} [inSlider] Признак для вывода в слайдер на главную
         * @param {ApiPostsGetInSidebarEnum} [inSidebar] Признак для вывода в сайдбар
         * @param {ApiPostsGetIsExclusiveEnum} [isExclusive] Признак тегирования тегом &lt;Эксклюзив&gt;
         * @param {ApiPostsGetIsTopEnum} [isTop] Признак тегирования тегом &lt;Топ&gt;
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {Array<ApiPostsGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsGet(page?: number, pageSize?: number, onMain?: ApiPostsGetOnMainEnum, inSlider?: ApiPostsGetInSliderEnum, inSidebar?: ApiPostsGetInSidebarEnum, isExclusive?: ApiPostsGetIsExclusiveEnum, isTop?: ApiPostsGetIsTopEnum, since?: string, to?: string, year?: number, month?: number, _with?: Array<ApiPostsGetWithEnum>, options?: any): AxiosPromise<Array<Post>> {
            return localVarFp.apiPostsGet(page, pageSize, onMain, inSlider, inSidebar, isExclusive, isTop, since, to, year, month, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Post по ID
         * @param {number} id
         * @param {Array<ApiPostsIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsIdGet(id: number, _with?: Array<ApiPostsIdGetWithEnum>, options?: any): AxiosPromise<Post> {
            return localVarFp.apiPostsIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию MatchPost[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiPostsMatchGetCompetitionStatusEnum} [competitionStatus]
         * @param {string} [since]
         * @param {string} [to]
         * @param {number} [year]
         * @param {number} [month]
         * @param {string} [start]
         * @param {string} [end]
         * @param {Array<ApiPostsMatchGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsMatchGet(page?: number, pageSize?: number, competitionStatus?: ApiPostsMatchGetCompetitionStatusEnum, since?: string, to?: string, year?: number, month?: number, start?: string, end?: string, _with?: Array<ApiPostsMatchGetWithEnum>, options?: any): AxiosPromise<Array<MatchPost>> {
            return localVarFp.apiPostsMatchGet(page, pageSize, competitionStatus, since, to, year, month, start, end, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает MatchPost по ID
         * @param {number} id
         * @param {Array<ApiPostsMatchIdGetWithEnum>} [_with]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostsMatchIdGet(id: number, _with?: Array<ApiPostsMatchIdGetWithEnum>, options?: any): AxiosPromise<MatchPost> {
            return localVarFp.apiPostsMatchIdGet(id, _with, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию DefaultPost[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsDefaultGet(q: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ApiSearchPostsGroupedGet200ResponseDefault> {
            return localVarFp.apiSearchPostsDefaultGet(q, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию EventPost[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsEventGet(q: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ApiSearchPostsGroupedGet200ResponseEvent> {
            return localVarFp.apiSearchPostsEventGet(q, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Post[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsGet(q: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ApiSearchPostsGet200Response> {
            return localVarFp.apiSearchPostsGet(q, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию DefaultPost[], EventPost[] и MatchPost[] разбитых по группам
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsGroupedGet(q: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ApiSearchPostsGroupedGet200Response> {
            return localVarFp.apiSearchPostsGroupedGet(q, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию MatchPost[]
         * @param {string} q Поисковой запрос
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchPostsMatchGet(q: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ApiSearchPostsGroupedGet200ResponseMatch> {
            return localVarFp.apiSearchPostsMatchGet(q, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию сезонов HdbkSeason[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeasonsGet(page?: number, pageSize?: number, options?: any): AxiosPromise<Array<HdbkSeason>> {
            return localVarFp.apiSeasonsGet(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает HdbkSeason по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSeasonsIdGet(id: number, options?: any): AxiosPromise<HdbkSeason> {
            return localVarFp.apiSeasonsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiSocialsGetModuleEnum} [module]
         * @param {number} [itemId]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialsGet(module?: ApiSocialsGetModuleEnum, itemId?: number, page?: number, options?: any): AxiosPromise<Array<Social>> {
            return localVarFp.apiSocialsGet(module, itemId, page, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialsIdGet(id: number, options?: any): AxiosPromise<Social> {
            return localVarFp.apiSocialsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSocialsSiteGet(options?: any): AxiosPromise<Array<Social>> {
            return localVarFp.apiSocialsSiteGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию HdbkSportKind[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiSportKindsGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSportKindsGet(page?: number, pageSize?: number, onMain?: ApiSportKindsGetOnMainEnum, options?: any): AxiosPromise<Array<HdbkSportKind>> {
            return localVarFp.apiSportKindsGet(page, pageSize, onMain, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает HdbkSportKind по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSportKindsIdGet(id: number, options?: any): AxiosPromise<HdbkSportKind> {
            return localVarFp.apiSportKindsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию подписок Subscription[]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionGet(options?: any): AxiosPromise<Array<Subscription>> {
            return localVarFp.apiSubscriptionGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Добавить email в подписки
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionSubscribePost(email: string, options?: any): AxiosPromise<Subscription> {
            return localVarFp.apiSubscriptionSubscribePost(email, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Отписать email от подписки
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionUnsubscribeDelete(email: string, options?: any): AxiosPromise<Subscription> {
            return localVarFp.apiSubscriptionUnsubscribeDelete(email, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet(options?: any): AxiosPromise<ApiVersionGet200Response> {
            return localVarFp.apiVersionGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Video[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiVideosGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {ApiVideosGetInPlayerEnum} [inPlayer] Признак для вывода в плеер
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVideosGet(page?: number, pageSize?: number, onMain?: ApiVideosGetOnMainEnum, inPlayer?: ApiVideosGetInPlayerEnum, options?: any): AxiosPromise<Array<Video>> {
            return localVarFp.apiVideosGet(page, pageSize, onMain, inPlayer, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Video по ID
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVideosIdGet(id: number, options?: any): AxiosPromise<Video> {
            return localVarFp.apiVideosIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает самый актуальный Vote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesActualGet(options?: any): AxiosPromise<Vote> {
            return localVarFp.apiVotesActualGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию Vote[]
         * @param {number} [page]
         * @param {number} [pageSize]
         * @param {ApiVotesGetOnMainEnum} [onMain] Признак для вывода на главной
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesGet(page?: number, pageSize?: number, onMain?: ApiVotesGetOnMainEnum, options?: any): AxiosPromise<Array<Vote>> {
            return localVarFp.apiVotesGet(page, pageSize, onMain, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Создаёт ответ VoteAnswer
         * @param {number} id ID опроса
         * @param {number} optionId ID варианта ответа
         * @param {string} ip IP c которого пришел ответ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdAnswerPost(id: number, optionId: number, ip: string, options?: any): AxiosPromise<ApiVotesIdAnswerPost200Response> {
            return localVarFp.apiVotesIdAnswerPost(id, optionId, ip, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает коллекцию VoteAnswer[]
         * @param {string} id ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdAnswersGet(id: string, options?: any): AxiosPromise<Array<VoteAnswer>> {
            return localVarFp.apiVotesIdAnswersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает VoteAnswer по hash
         * @param {string} id ID опроса
         * @param {string} hash Хеш ответа пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdAnswersHashGet(id: string, hash: string, options?: any): AxiosPromise<VoteAnswer> {
            return localVarFp.apiVotesIdAnswersHashGet(id, hash, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Возвращает Vote по ID
         * @param {number} id ID опроса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdGet(id: number, options?: any): AxiosPromise<Vote> {
            return localVarFp.apiVotesIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @summary Возвращает коллекцию Album[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiAlbumsGetOnMainEnum} [onMain] Признак для вывода на главной
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiAlbumsGet(page?: number, pageSize?: number, onMain?: ApiAlbumsGetOnMainEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiAlbumsGet(page, pageSize, onMain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Album по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiAlbumsIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiAlbumsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Banner[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiBannersGetCurrentEnum} [current]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiBannersGet(page?: number, pageSize?: number, current?: ApiBannersGetCurrentEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiBannersGet(page, pageSize, current, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Banner по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiBannersIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiBannersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию соревнований Competition[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {number} [sportKindId] ID вида спорта HdbkSportKind
     * @param {number} [seasonId] ID сезона HdbkSeason
     * @param {string} [name] Название соревнования Competition
     * @param {Array<ApiCompetitionsGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCompetitionsGet(page?: number, pageSize?: number, sportKindId?: number, seasonId?: number, name?: string, _with?: Array<ApiCompetitionsGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCompetitionsGet(page, pageSize, sportKindId, seasonId, name, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Competition по ID
     * @param {number} id
     * @param {Array<ApiCompetitionsIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCompetitionsIdGet(id: number, _with?: Array<ApiCompetitionsIdGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCompetitionsIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает MatchPost[] по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCompetitionsIdMatchesGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCompetitionsIdMatchesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает MatchPost по ID
     * @param {number} id
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCompetitionsIdMatchesPostIdGet(id: number, postId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCompetitionsIdMatchesPostIdGet(id, postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию CompetitionTable[]
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCompetitionsIdTablesGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCompetitionsIdTablesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает CompetitionTable по ID
     * @param {number} id
     * @param {number} tableId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCompetitionsIdTablesTableIdGet(id: number, tableId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCompetitionsIdTablesTableIdGet(id, tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Organization[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiOrganizationsGetTypeEnum} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiOrganizationsGet(page?: number, pageSize?: number, type?: ApiOrganizationsGetTypeEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiOrganizationsGet(page, pageSize, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Organization по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiOrganizationsIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiOrganizationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Page по alias
     * @param {ApiPagesAliasGetAliasEnum} alias
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPagesAliasGet(alias: ApiPagesAliasGetAliasEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPagesAliasGet(alias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Page[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {number} [page2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPagesGet(page?: number, pageSize?: number, page2?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPagesGet(page, pageSize, page2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Page по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPagesIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPagesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию HdbkPartner[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiPartnersGetOnMainEnum} [onMain] Признак для вывода на главной
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPartnersGet(page?: number, pageSize?: number, onMain?: ApiPartnersGetOnMainEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPartnersGet(page, pageSize, onMain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает HdbkPartner по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPartnersIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPartnersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию VideoPlaylist[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPlaylistsGet(page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPlaylistsGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Playlist по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPlaylistsIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPlaylistsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию DefaultPost[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {number} [sportKindId]
     * @param {ApiPostsDefaultGetIsExclusiveEnum} [isExclusive] Признак тегирования тегом &lt;Эксклюзив&gt;
     * @param {ApiPostsDefaultGetIsTopEnum} [isTop] Признак тегирования тегом &lt;Топ&gt;
     * @param {string} [since]
     * @param {string} [to]
     * @param {number} [year]
     * @param {number} [month]
     * @param {Array<ApiPostsDefaultGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPostsDefaultGet(page?: number, pageSize?: number, sportKindId?: number, isExclusive?: ApiPostsDefaultGetIsExclusiveEnum, isTop?: ApiPostsDefaultGetIsTopEnum, since?: string, to?: string, year?: number, month?: number, _with?: Array<ApiPostsDefaultGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPostsDefaultGet(page, pageSize, sportKindId, isExclusive, isTop, since, to, year, month, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает DefaultPost по ID
     * @param {number} id
     * @param {Array<ApiPostsDefaultIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPostsDefaultIdGet(id: number, _with?: Array<ApiPostsDefaultIdGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPostsDefaultIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию EventPost[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {number} [sportKindId]
     * @param {string} [since]
     * @param {string} [to]
     * @param {number} [year]
     * @param {number} [month]
     * @param {string} [start]
     * @param {string} [end]
     * @param {Array<ApiPostsEventGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPostsEventGet(page?: number, pageSize?: number, sportKindId?: number, since?: string, to?: string, year?: number, month?: number, start?: string, end?: string, _with?: Array<ApiPostsEventGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPostsEventGet(page, pageSize, sportKindId, since, to, year, month, start, end, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает EventPost по ID
     * @param {number} id
     * @param {Array<ApiPostsEventIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPostsEventIdGet(id: number, _with?: Array<ApiPostsEventIdGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPostsEventIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Post[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiPostsGetOnMainEnum} [onMain] Признак для вывода на главной
     * @param {ApiPostsGetInSliderEnum} [inSlider] Признак для вывода в слайдер на главную
     * @param {ApiPostsGetInSidebarEnum} [inSidebar] Признак для вывода в сайдбар
     * @param {ApiPostsGetIsExclusiveEnum} [isExclusive] Признак тегирования тегом &lt;Эксклюзив&gt;
     * @param {ApiPostsGetIsTopEnum} [isTop] Признак тегирования тегом &lt;Топ&gt;
     * @param {string} [since]
     * @param {string} [to]
     * @param {number} [year]
     * @param {number} [month]
     * @param {Array<ApiPostsGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPostsGet(page?: number, pageSize?: number, onMain?: ApiPostsGetOnMainEnum, inSlider?: ApiPostsGetInSliderEnum, inSidebar?: ApiPostsGetInSidebarEnum, isExclusive?: ApiPostsGetIsExclusiveEnum, isTop?: ApiPostsGetIsTopEnum, since?: string, to?: string, year?: number, month?: number, _with?: Array<ApiPostsGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPostsGet(page, pageSize, onMain, inSlider, inSidebar, isExclusive, isTop, since, to, year, month, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Post по ID
     * @param {number} id
     * @param {Array<ApiPostsIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPostsIdGet(id: number, _with?: Array<ApiPostsIdGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPostsIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию MatchPost[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiPostsMatchGetCompetitionStatusEnum} [competitionStatus]
     * @param {string} [since]
     * @param {string} [to]
     * @param {number} [year]
     * @param {number} [month]
     * @param {string} [start]
     * @param {string} [end]
     * @param {Array<ApiPostsMatchGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPostsMatchGet(page?: number, pageSize?: number, competitionStatus?: ApiPostsMatchGetCompetitionStatusEnum, since?: string, to?: string, year?: number, month?: number, start?: string, end?: string, _with?: Array<ApiPostsMatchGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPostsMatchGet(page, pageSize, competitionStatus, since, to, year, month, start, end, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает MatchPost по ID
     * @param {number} id
     * @param {Array<ApiPostsMatchIdGetWithEnum>} [_with]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPostsMatchIdGet(id: number, _with?: Array<ApiPostsMatchIdGetWithEnum>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPostsMatchIdGet(id, _with, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию DefaultPost[]
     * @param {string} q Поисковой запрос
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSearchPostsDefaultGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSearchPostsDefaultGet(q, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию EventPost[]
     * @param {string} q Поисковой запрос
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSearchPostsEventGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSearchPostsEventGet(q, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Post[]
     * @param {string} q Поисковой запрос
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSearchPostsGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSearchPostsGet(q, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию DefaultPost[], EventPost[] и MatchPost[] разбитых по группам
     * @param {string} q Поисковой запрос
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSearchPostsGroupedGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSearchPostsGroupedGet(q, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию MatchPost[]
     * @param {string} q Поисковой запрос
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSearchPostsMatchGet(q: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSearchPostsMatchGet(q, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию сезонов HdbkSeason[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSeasonsGet(page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSeasonsGet(page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает HdbkSeason по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSeasonsIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSeasonsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {ApiSocialsGetModuleEnum} [module]
     * @param {number} [itemId]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSocialsGet(module?: ApiSocialsGetModuleEnum, itemId?: number, page?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSocialsGet(module, itemId, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSocialsIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSocialsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSocialsSiteGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSocialsSiteGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию HdbkSportKind[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiSportKindsGetOnMainEnum} [onMain] Признак для вывода на главной
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSportKindsGet(page?: number, pageSize?: number, onMain?: ApiSportKindsGetOnMainEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSportKindsGet(page, pageSize, onMain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает HdbkSportKind по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSportKindsIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSportKindsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию подписок Subscription[]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSubscriptionGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSubscriptionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Добавить email в подписки
     * @param {string} email Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSubscriptionSubscribePost(email: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSubscriptionSubscribePost(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Отписать email от подписки
     * @param {string} email Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSubscriptionUnsubscribeDelete(email: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSubscriptionUnsubscribeDelete(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiVersionGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiVersionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Video[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiVideosGetOnMainEnum} [onMain] Признак для вывода на главной
     * @param {ApiVideosGetInPlayerEnum} [inPlayer] Признак для вывода в плеер
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiVideosGet(page?: number, pageSize?: number, onMain?: ApiVideosGetOnMainEnum, inPlayer?: ApiVideosGetInPlayerEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiVideosGet(page, pageSize, onMain, inPlayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Video по ID
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiVideosIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiVideosIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает самый актуальный Vote
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiVotesActualGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiVotesActualGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию Vote[]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {ApiVotesGetOnMainEnum} [onMain] Признак для вывода на главной
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiVotesGet(page?: number, pageSize?: number, onMain?: ApiVotesGetOnMainEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiVotesGet(page, pageSize, onMain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Создаёт ответ VoteAnswer
     * @param {number} id ID опроса
     * @param {number} optionId ID варианта ответа
     * @param {string} ip IP c которого пришел ответ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiVotesIdAnswerPost(id: number, optionId: number, ip: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiVotesIdAnswerPost(id, optionId, ip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает коллекцию VoteAnswer[]
     * @param {string} id ID опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiVotesIdAnswersGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiVotesIdAnswersGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает VoteAnswer по hash
     * @param {string} id ID опроса
     * @param {string} hash Хеш ответа пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiVotesIdAnswersHashGet(id: string, hash: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiVotesIdAnswersHashGet(id, hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Возвращает Vote по ID
     * @param {number} id ID опроса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiVotesIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiVotesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiAlbumsGetOnMainEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiAlbumsGetOnMainEnum = typeof ApiAlbumsGetOnMainEnum[keyof typeof ApiAlbumsGetOnMainEnum];
/**
 * @export
 */
export const ApiBannersGetCurrentEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiBannersGetCurrentEnum = typeof ApiBannersGetCurrentEnum[keyof typeof ApiBannersGetCurrentEnum];
/**
 * @export
 */
export const ApiCompetitionsGetWithEnum = {
    Matches: 'matches'
} as const;
export type ApiCompetitionsGetWithEnum = typeof ApiCompetitionsGetWithEnum[keyof typeof ApiCompetitionsGetWithEnum];
/**
 * @export
 */
export const ApiCompetitionsIdGetWithEnum = {
    Matches: 'matches'
} as const;
export type ApiCompetitionsIdGetWithEnum = typeof ApiCompetitionsIdGetWithEnum[keyof typeof ApiCompetitionsIdGetWithEnum];
/**
 * @export
 */
export const ApiOrganizationsGetTypeEnum = {
    Governmental: 'governmental',
    Commercial: 'commercial'
} as const;
export type ApiOrganizationsGetTypeEnum = typeof ApiOrganizationsGetTypeEnum[keyof typeof ApiOrganizationsGetTypeEnum];
/**
 * @export
 */
export const ApiPagesAliasGetAliasEnum = {
    Contacts: 'contacts',
    Redaction: 'redaction',
    Partners: 'partners',
    Vacancy: 'vacancy',
    Advertisement: 'advertisement',
    Privacy: 'privacy',
    Agreement: 'agreement'
} as const;
export type ApiPagesAliasGetAliasEnum = typeof ApiPagesAliasGetAliasEnum[keyof typeof ApiPagesAliasGetAliasEnum];
/**
 * @export
 */
export const ApiPartnersGetOnMainEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiPartnersGetOnMainEnum = typeof ApiPartnersGetOnMainEnum[keyof typeof ApiPartnersGetOnMainEnum];
/**
 * @export
 */
export const ApiPostsDefaultGetIsExclusiveEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiPostsDefaultGetIsExclusiveEnum = typeof ApiPostsDefaultGetIsExclusiveEnum[keyof typeof ApiPostsDefaultGetIsExclusiveEnum];
/**
 * @export
 */
export const ApiPostsDefaultGetIsTopEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiPostsDefaultGetIsTopEnum = typeof ApiPostsDefaultGetIsTopEnum[keyof typeof ApiPostsDefaultGetIsTopEnum];
/**
 * @export
 */
export const ApiPostsDefaultGetWithEnum = {
    Documents: 'documents',
    Albums: 'albums',
    Videos: 'videos'
} as const;
export type ApiPostsDefaultGetWithEnum = typeof ApiPostsDefaultGetWithEnum[keyof typeof ApiPostsDefaultGetWithEnum];
/**
 * @export
 */
export const ApiPostsDefaultIdGetWithEnum = {
    Documents: 'documents',
    Albums: 'albums',
    Videos: 'videos'
} as const;
export type ApiPostsDefaultIdGetWithEnum = typeof ApiPostsDefaultIdGetWithEnum[keyof typeof ApiPostsDefaultIdGetWithEnum];
/**
 * @export
 */
export const ApiPostsEventGetWithEnum = {
    Documents: 'documents',
    Albums: 'albums',
    Videos: 'videos',
    SportKinds: 'sportKinds'
} as const;
export type ApiPostsEventGetWithEnum = typeof ApiPostsEventGetWithEnum[keyof typeof ApiPostsEventGetWithEnum];
/**
 * @export
 */
export const ApiPostsEventIdGetWithEnum = {
    Documents: 'documents',
    Albums: 'albums',
    Videos: 'videos'
} as const;
export type ApiPostsEventIdGetWithEnum = typeof ApiPostsEventIdGetWithEnum[keyof typeof ApiPostsEventIdGetWithEnum];
/**
 * @export
 */
export const ApiPostsGetOnMainEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiPostsGetOnMainEnum = typeof ApiPostsGetOnMainEnum[keyof typeof ApiPostsGetOnMainEnum];
/**
 * @export
 */
export const ApiPostsGetInSliderEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiPostsGetInSliderEnum = typeof ApiPostsGetInSliderEnum[keyof typeof ApiPostsGetInSliderEnum];
/**
 * @export
 */
export const ApiPostsGetInSidebarEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiPostsGetInSidebarEnum = typeof ApiPostsGetInSidebarEnum[keyof typeof ApiPostsGetInSidebarEnum];
/**
 * @export
 */
export const ApiPostsGetIsExclusiveEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiPostsGetIsExclusiveEnum = typeof ApiPostsGetIsExclusiveEnum[keyof typeof ApiPostsGetIsExclusiveEnum];
/**
 * @export
 */
export const ApiPostsGetIsTopEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiPostsGetIsTopEnum = typeof ApiPostsGetIsTopEnum[keyof typeof ApiPostsGetIsTopEnum];
/**
 * @export
 */
export const ApiPostsGetWithEnum = {
    Documents: 'documents',
    Albums: 'albums',
    Videos: 'videos'
} as const;
export type ApiPostsGetWithEnum = typeof ApiPostsGetWithEnum[keyof typeof ApiPostsGetWithEnum];
/**
 * @export
 */
export const ApiPostsIdGetWithEnum = {
    Documents: 'documents',
    Albums: 'albums',
    Videos: 'videos'
} as const;
export type ApiPostsIdGetWithEnum = typeof ApiPostsIdGetWithEnum[keyof typeof ApiPostsIdGetWithEnum];
/**
 * @export
 */
export const ApiPostsMatchGetCompetitionStatusEnum = {
    Current: 'current',
    Past: 'past',
    Future: 'future'
} as const;
export type ApiPostsMatchGetCompetitionStatusEnum = typeof ApiPostsMatchGetCompetitionStatusEnum[keyof typeof ApiPostsMatchGetCompetitionStatusEnum];
/**
 * @export
 */
export const ApiPostsMatchGetWithEnum = {
    Documents: 'documents',
    Albums: 'albums',
    Videos: 'videos'
} as const;
export type ApiPostsMatchGetWithEnum = typeof ApiPostsMatchGetWithEnum[keyof typeof ApiPostsMatchGetWithEnum];
/**
 * @export
 */
export const ApiPostsMatchIdGetWithEnum = {
    Documents: 'documents',
    Albums: 'albums',
    Videos: 'videos'
} as const;
export type ApiPostsMatchIdGetWithEnum = typeof ApiPostsMatchIdGetWithEnum[keyof typeof ApiPostsMatchIdGetWithEnum];
/**
 * @export
 */
export const ApiSocialsGetModuleEnum = {
    Settings: 'settings',
    Organization: 'organization'
} as const;
export type ApiSocialsGetModuleEnum = typeof ApiSocialsGetModuleEnum[keyof typeof ApiSocialsGetModuleEnum];
/**
 * @export
 */
export const ApiSportKindsGetOnMainEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiSportKindsGetOnMainEnum = typeof ApiSportKindsGetOnMainEnum[keyof typeof ApiSportKindsGetOnMainEnum];
/**
 * @export
 */
export const ApiVideosGetOnMainEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiVideosGetOnMainEnum = typeof ApiVideosGetOnMainEnum[keyof typeof ApiVideosGetOnMainEnum];
/**
 * @export
 */
export const ApiVideosGetInPlayerEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiVideosGetInPlayerEnum = typeof ApiVideosGetInPlayerEnum[keyof typeof ApiVideosGetInPlayerEnum];
/**
 * @export
 */
export const ApiVotesGetOnMainEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type ApiVotesGetOnMainEnum = typeof ApiVotesGetOnMainEnum[keyof typeof ApiVotesGetOnMainEnum];


